.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  @import "select2/single";
  @import "select2/multiple";
}
@import "select2/dropdown";
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left:0;
  top:0;
  min-height:100%;
  min-width:100%;
  height:auto;
  width:auto;
  opacity:0;
  z-index:99;
  background-color:transparent;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}


.select2{
  max-width:100% ;
}
.select2-container{
  width:100% !important ;
}




@import "select2/theme/default/layout";
@import "select2/theme/classic/layout";
